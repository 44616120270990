//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Image } from "element-ui";
import { ctrlOrder, ctrlBase } from "@/controller";
import { GoodsItem } from "@/components";
import BaseImageViewer from "@/components/Base/BaseImageViewer/BaseImageViewer.vue";
export default {
    props: {
        nbr: String,
    },
    components: {
        GoodsItem,
        BaseImageViewer,
        [Image.name]: Image,
    },
    data() {
        return {
            retInfo: {},
            getSrcList:[]
        };
    },
    created() {
        this.getInit();
    },
    watch: {},
    computed: {},
    methods: {
        getInit() {
            this.getRetList2();
        },
        async getRetList2() {
            this.retInfo = await ctrlOrder.getRetList2({ soNbr: this.nbr });
        },
        //圖片放大
        onViewer(index,target) {
            this.getSrcList = target.slice(index).concat(target.slice(0, index));
            this.$refs.BaseImageViewer.showViewer = true;
        },
    },
};
