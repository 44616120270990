//
//
//
//

import Vue from "vue";
import { Image } from "element-ui";
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
Vue.use(Image);
export default {
    props: {
        srcList: {
            type: [Array, String],
            default: () => [],
        },
    },
    components: {
        ElImageViewer,
    },
    name: "BaseImageViewer",
    data() {
        return {
            showViewer: false,
        };
    },
    computed: {
        _srcList() {
            if (this.Common.isExist(this.srcList)) {
                if (this.srcList instanceof Array) return this.srcList;
                return [this.srcList];
            }
        },
    },
    mounted() {
        // //console.log(this.DiscountObj);
    },
    created() {},
    methods: {
        closeViewer() {
            this.showViewer = false;
        },
    },
};
